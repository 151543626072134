import { SharedoProfile } from "@sharedo/mobile-core";
import sharedoActionService from "./sharedoActionService.js";
import router from "../../router/index.js";

// TODO move to core
function getSharedoActionsMenu(config) {
    var defaults = {
        id: null,
        includeChronologyLink: true,
        includeParticipantsLink: true,
        rolePermissions: [],
        phaseInfo: null,                // Required for transitions
        participantActions: null,       // Required for ownership actions
        afterTakeOwnership: null,
        afterTransitionTo: null,
        bookmarking: null
    };

    var options = Object.assign(defaults, config);

    if (!options.id) throw "getSharedoActionsMenu: no id";

    // Permissions
    var canViewParticipants = options.rolePermissions.includes("core.sharedo.participant.read");
    var canViewChronology = SharedoProfile.profile.globalPermissions.includes("core.audit.chronology");
    var canProgress = options.rolePermissions.includes("core.sharedo.progress.milestone");

    var items = [];

    // View participants
    if (canViewParticipants && options.includeParticipantsLink) {
        items.push({ text: "Participants", color: "", icon: "mdi-account-outline", handler: navTo.bind(this, "participants") });
    }

    // View chronology
    if (canViewChronology && options.includeChronologyLink) {
        items.push({ text: "Chronology", color: "", icon: "mdi-flag-outline", handler: navTo.bind(this, "chronology") });
    }

    // If any items, add "View" header
    if (items.length) {
        items.unshift({ text: "View", type: "header" });
    }

    const actionItems = []

    // Ownership
    if (options.participantActions && options.participantActions.canTakeOwnership) {
        var takeOpts = {
            id: options.id,
            callback: options.afterTakeOwnership,
        };

        actionItems.push(
            { text: "Take ownership", color: "primary", icon: "mdi-arrow-left", handler: sharedoActionService.takeOwnership.bind(this, takeOpts) },
        );
    }

    // Bookmarking
    if (options.bookmarking && options.bookmarking.enabled) {
        const { bookmarked, onBookmarkChange } = options.bookmarking;

        const handler = async () => {
            await sharedoActionService.bookmark(options.id, !bookmarked);
            if (typeof onBookmarkChange === "function") {
                onBookmarkChange(!bookmarked);
            }
        }

        if (bookmarked) {
            actionItems.push({ text: "Remove bookmark", color: "primary", icon: "mdi-star-off-outline", handler });
        } else {
            actionItems.push({ text: "Bookmark", color: "primary", icon: "mdi-star-outline", handler });
        }
    }

    if (actionItems.length > 0) {
        items.push({ text: "Actions", type: "header" }, ...actionItems);
    }

    // Transitions
    if (canProgress && options.phaseInfo) {

        var transOpts = {
            sharedoId: options.id,
            callback: options.afterTransitionTo,
        }

        var trans = options.phaseInfo.availableTransitions
            .filter(t => t.isUserDriven)
            .map(t => {
                var transTo = Object.assign({}, t, transOpts);

                return {
                    text: t.name,
                    color: t.isOptimumPath ? "primary" : null,
                    icon: t.toPhaseIconClass || "mdi-fast-forward-outline",
                    handler: sharedoActionService.confirmTransitionTo.bind(this, transTo),
                };
            });

        if (trans.length)
            items.push({ text: "Progress to", type: "header" }, ...trans);
    }

    if (items.length === 0) items.push({ text: "No actions available", type: "header" });

    return items;
}

// Private methods
function navTo(subPage) {
    router.push({ path: `${window.location.pathname}/${subPage}` });
}

export default
    {
        getSharedoActionsMenu,
    };
