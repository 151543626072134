import { SharedoFetch, SharedoProfile } from '@sharedo/mobile-core'

function getMyTasks(rpp, p, scope)
{
    var myScope = null;
    var teamScope = null;

    if (scope === "me") {
        myScope = {
            ownerIds:[SharedoProfile.profile.userId],
            primary: true,
            secondary: true
        };
    }
    else if (scope === "team") {
        teamScope = {
            ownerIds:["92A31F64-88CE-4975-9E2A-F8EC89C51162"],  // HACK - include tasks assigned to ACL Investigators
            primary: true,
            secondary: true
        };
    }
    // else show all (no restrictions)

    return SharedoFetch.post("/api/v1/public/workItem/findByQuery",
    {
        search:
        {
            page: { rowsPerPage: rpp, page: p },
            sort: {
                orderBy: "dueDate",
                direction: "ascending"
            },
            types: 
            { 
                includeTypes: [
                ],
                includeTypesDerivedFrom: [
                    "vm-pl-investigation",
                ],
            },
            phase:
            {
                IncludeClosed: false,
                includeRemoved: false,
                searchPhaseName: "Doing",
            },
            ownership:
            {
                myScope: myScope,
                teamScope: teamScope,
                //teamMateScope: ... (this expects team mate IDs)
            }
        },
        enrich:
        [
            { path: "reference" },
            { path: "title" },
            { path: "createdDate.utc.value"},
            { path: "taskDueDate.date.local.value" },
            { path: "type.name" },
            { path: "type.iconClass" },
            { path: "phase.name" },
            { path: "roles.primary-owner.ods.name" },
            { path: "roles.primary-owner.participantType.iconClass" },
            { path: "documents!1.title" },
        ]
    });
}

function getTask(id)
{
    if( !id ) throw "getTask: no id";

    return SharedoFetch.get(`/api/v1/public/workItem/${id}`);
}

/* updateRequest is:
 * {
 *      workItem: { ... },
 *      AspectData: { foo: ... }
 * }
 */
function saveTask(id, updateRequest)
{
    return SharedoFetch.put(`/api/v1/public/workItem/${id}`, updateRequest);
}

function getTaskActions(id)
{
    // TODO add participant action info to public API?
    // TODO how do we get sharedo type icon? (path query?)
    return SharedoFetch.get(`/api/tasks/${id}`).then(r => r.participantActions);
}

function getPhaseInfo(id)
{
    return SharedoFetch.get(`/api/v1/public/workItem/${id}/phase`);
}

function setPhase(id, phaseSystemName, reasonText)
{
    return SharedoFetch.put(`/api/v1/public/workItem/${id}/phase`,
        {
            toPhaseSystemName: phaseSystemName,
            reasonText: reasonText,
            description: "From Mobile"
        });
}

function getPermissions(id)
{
    // TODO public API? Or move to core?
    return SharedoFetch.get(`/api/sharedo/${id}/mySharedoPermissions`);
}

function takeOwnership(id)
{
    // TODO public API?
    return SharedoFetch.post(`/api/tasks/${id}/takeownership`, {});
}

export default 
{
    getMyTasks,
    getTask,
    saveTask,
    getTaskActions,
    getPhaseInfo,
    setPhase,
    getPermissions,
    takeOwnership,
};
