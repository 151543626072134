import tasksAgent from "../../views/Tasks/tasksAgent.js";
import bookmarksAgent from "@/views/Bookmarks/bookmarksAgent";
import { CoreUi } from '@sharedo/mobile-core'

// TODO move to core
function takeOwnership(config) {
    var defaults = {
        id: null,
        callback: null,
    };

    var options = Object.assign(defaults, config);

    var loading = CoreUi.loading();

    tasksAgent.takeOwnership(options.id).then(() => {
        loading.dismiss();

        CoreUi.toast("You now own this item.");

        if (typeof options.callback === "function") options.callback();

    }).catch(console.error);
}

function confirmTransitionTo(config) {
    var defaults = {
        name: null,
        // See transitionTo for more
    };

    var options = Object.assign(defaults, config);

    CoreUi.messageBox({
        title: "Progress to " + options.name,
        message: "Are you sure?",
        btns: [
            { text: "Cancel" },
            { text: "OK", color: "primary", handler: () => transitionTo(options) }
        ]
    });
}

function transitionTo(config) {
    var defaults = {
        sharedoId: null,
        toPhaseSystemName: null,
        name: null,
        callback: null,
        shouldCloseUi: false,
        reasonText: null,
    };

    var options = Object.assign(defaults, config);

    var loading = CoreUi.loading();

    tasksAgent.setPhase(options.sharedoId, options.toPhaseSystemName, options.reasonText).then(() => {
        loading.dismiss();
        CoreUi.toast("Set to " + options.name);

        if (typeof options.callback === "function") options.callback(options);

    }).catch(console.error);
}

function bookmark(sharedoId, set) {
    if (set) {
        return bookmarksAgent.bookmark(sharedoId);
    } else {
        return bookmarksAgent.removeBookmark(sharedoId);
    }
}

export default
    {
        takeOwnership,
        confirmTransitionTo,
        transitionTo,
        bookmark
    };
